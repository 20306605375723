import { useNavigate } from "react-router-dom";
import { Button, Field, Heading, Input, Stack } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useAuthRegister } from "../../hooks/auth";
import { PasswordInput } from "../../components/PasswordInput";
import { onErrorShared } from "../../rpc/error";
import { LoginCta } from "./cta";

interface FormFields {
  name: string;
  email: string;
  password: string;
}

export function AuthRegisterPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormFields>();
  const navigate = useNavigate();
  const authRegister = useAuthRegister({
    onSuccess() {
      navigate("/todo", { replace: true });
    },
    onError: onErrorShared({
      invalid_argument(fields) {
        const formFields = ["name", "email", "password"] as const;
        formFields.forEach((key) => {
          const message = fields[key];
          if (message) {
            setError(key, { message });
          }
        });
      },
    }),
  });

  function onSubmit(data: { email: string; password: string; name: string }) {
    authRegister.mutate({ body: data });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={8} w="full" maxW="md">
        <Heading fontSize="2xl">Create your account</Heading>
        <Field.Root id="name" invalid={!!errors.name}>
          <Field.Label>Your Name</Field.Label>
          <Input
            autoFocus
            width="100%"
            type="text"
            {...register("name", {
              required: {
                value: true,
                message: "Name is required",
              },
            })}
          />
          <Field.ErrorText>{errors.name?.message}</Field.ErrorText>
        </Field.Root>
        <Field.Root id="email" invalid={!!errors.email}>
          <Field.Label>Email address</Field.Label>
          <Input
            width="100%"
            type="email"
            {...register("email", {
              required: {
                value: true,
                message: "Email is required",
              },
            })}
          />
          <Field.ErrorText>{errors.email?.message}</Field.ErrorText>
        </Field.Root>
        <Field.Root id="password" invalid={!!errors.password}>
          <Field.Label>Password</Field.Label>
          <PasswordInput
            width="100%"
            {...register("password", {
              required: {
                value: true,
                message: "Please provide a password",
              },
              minLength: {
                value: 8,
                message: "Passwords must have 8 characters",
              },
            })}
          />
          <Field.ErrorText>{errors.password?.message}</Field.ErrorText>
        </Field.Root>
        <Stack gap={6}>
          <Button loading={authRegister.isPending} type="submit" colorScheme="blue" variant="solid">
            Register
          </Button>

          <LoginCta text="Already have an account" />
        </Stack>
      </Stack>
    </form>
  );
}
