import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Text, Button, Field, Heading, Input, Stack } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { GithubIcon, GoogleIcon } from "../../components/icons";
import { useAuthLogin } from "../../hooks/auth";
import { InputGroup } from "@/components/ui/input-group";
import { PasswordInput } from "../../components/PasswordInput";
import { onErrorShared } from "../../rpc/error";
import { useAuth } from "../../hooks/authStore";
import { ForgotCta, RegisterCta } from "./cta";

interface FormFields {
  email: string;
  password: string;
}

export default function AuthLoginPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = useForm<FormFields>();
  const login = useAuthLogin({
    onError: onErrorShared({
      not_found() {
        setError("email", { message: "unable to find a user matching" });
      },
      invalid_argument(fields: Record<string, string>) {
        const formFields = ["email", "password"] as const;

        formFields.forEach((key) => {
          const message = fields[key];
          if (message) {
            setError(key, { message });
          }
        });
      },
    }),
  });
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { search } = useLocation();

  useEffect(() => {
    if (isAuthenticated) {
      const query = new URLSearchParams(search);

      navigate(query.get("next") ?? "/", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  function onSubmit(data: FormFields) {
    login.mutate({ body: data });
  }

  function onOauthButton(provider: string) {
    const query = new URLSearchParams(search);

    navigate(`/auth/oauth/${provider}?next=${encodeURIComponent(query.get("next") ?? "")}`);
  }

  const email = watch("email") ?? "";

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={8} w="full" maxW="md">
        <Heading fontSize="2xl">Welcome back to ProjectX</Heading>
        <Text>Sign in to your account</Text>
        <Stack direction={{ base: "row" }}>
          <Button variant="outline" onClick={() => onOauthButton("google")} className="">
            <GoogleIcon boxSize="4" mr="2" /> Sign in with Google
          </Button>
          <Button variant="outline" onClick={() => onOauthButton("github")} className="">
            <GithubIcon boxSize="4" mr="2" /> Sign in with Github
          </Button>
        </Stack>
        <Field.Root id="email" invalid={!!errors.email}>
          <Field.Label>Email address</Field.Label>
          <InputGroup width="100%">
            <Input
              autoFocus
              type="email"
              {...register("email", {
                required: {
                  value: true,
                  message: "Email address is required",
                },
              })}
            />
          </InputGroup>
          <Field.ErrorText>{errors.email?.message}</Field.ErrorText>
        </Field.Root>
        <Field.Root id="password" invalid={!!errors.password}>
          <Field.Label>Password</Field.Label>
          <PasswordInput
            width="100%"
            {...register("password", {
              required: {
                value: true,
                message: "Password is required",
              },
            })}
          />
          <Field.ErrorText>{errors.password?.message}</Field.ErrorText>
        </Field.Root>
        <Stack gap={6}>
          <Button loading={login.isPending} type="submit" colorScheme="blue" variant="solid">
            Sign in
          </Button>
          <Stack direction={{ base: "column", sm: "row" }} align="start" justify="space-between">
            <RegisterCta text="Don't have an account?" />
            <ForgotCta text="Forgot Password?" email={email} />
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
}
