import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { Text, Button, Field, Heading, Input, Link, Stack, Alert, Spinner } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useAuthRecoverUpdate, useAuthRecoverVerify } from "../../hooks/auth";
import { assert } from "../../util/assert";
import { onErrorShared } from "../../rpc/error";
import { ForgotCta, LoginCta } from "./cta";

interface FormFields {
  password: string;
}

enum verifiedState {
  LOADING,
  INVALID,
  GOOD,
  USED,
  ERROR,
}

export function AuthRecoveryResetPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormFields>();
  const { userId, token } = useParams<{ userId: string; token: string }>();
  const { search } = useLocation();
  const navigate = useNavigate();
  const [verified, setVerified] = useState<verifiedState>(verifiedState.LOADING);
  const [completed, setCompleted] = useState(false);
  const recoverVerify = useAuthRecoverVerify({
    onSuccess() {
      setVerified(verifiedState.GOOD);
    },
    onError(error) {
      if ("code" in error) {
        setVerified(verifiedState.USED);
      } else {
        setVerified(verifiedState.ERROR);
      }
    },
  });
  const recoverUpdate = useAuthRecoverUpdate({
    onSuccess() {
      setCompleted(true);
      const query = new URLSearchParams(search);
      navigate(query.get("next") ?? "/", { replace: true });
    },
    onError: onErrorShared({
      invalid_argument(fields) {
        const formFields = ["password"] as const;
        formFields.forEach((key) => {
          const message = fields[key];
          if (message) {
            setError(key, { message });
          }
        });
      },
    }),
  });

  useEffect(() => {
    if (userId && token) {
      recoverVerify.mutate({ body: { user_id: userId, token } });
    } else {
      setVerified(verifiedState.INVALID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, token]);

  function onSubmit({ password }: { password: string }) {
    assert(userId && token);

    recoverUpdate.mutate({ body: { user_id: userId, token, password } });
  }

  switch (verified) {
    case verifiedState.LOADING:
      return (
        <Stack gap={8} w="full" maxW="md" align="center">
          <Spinner />
          <Text>Verifing link</Text>
        </Stack>
      );
    case verifiedState.GOOD:
      return (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack gap={8} w="full" maxW="md">
            <Heading fontSize="2xl">Reset your password</Heading>
            <Field.Root id="password" invalid={!!errors.password}>
              <Field.Label>Enter your new password</Field.Label>
              <Input
                type="password"
                {...register("password", {
                  required: {
                    value: true,
                    message: "Please provide a password",
                  },
                  minLength: {
                    value: 8,
                    message: "Passwords must have 8 characters",
                  },
                })}
              />
              <Field.ErrorText>{errors.password?.message}</Field.ErrorText>
            </Field.Root>
            <Stack gap={6}>
              <Button
                loading={recoverUpdate.isPending}
                disabled={completed}
                type="submit"
                colorScheme="blue"
                variant="solid"
              >
                Reset your password
              </Button>
              <Stack direction={{ base: "column", sm: "row" }} align="start" justify="space-between">
                <LoginCta />
                <ForgotCta />
              </Stack>
            </Stack>
          </Stack>
        </form>
      );
    case verifiedState.USED:
      return (
        <Alert.Root status="error">
          <Alert.Title>Already used</Alert.Title>
          <Alert.Description>It appears that this link has already been used</Alert.Description>
        </Alert.Root>
      );
    case verifiedState.ERROR:
      return (
        <Alert.Root status="info">
          <Alert.Title>Unknown error</Alert.Title>
          <Alert.Description>Possibly network error, please try again</Alert.Description>
        </Alert.Root>
      );
    case verifiedState.INVALID:
      return (
        <Alert.Root status="error">
          <Alert.Indicator />
          <Alert.Title>Invalid recovery URL</Alert.Title>
          <Alert.Description>
            <Link asChild colorPalette="blue">
              <RouterLink replace to="/auth/login">
                Back to login page
              </RouterLink>
            </Link>
          </Alert.Description>
        </Alert.Root>
      );
  }
}
