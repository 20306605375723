// https://dribbble.com/shots/15186840-Setting-page-example

import { Text, Heading, Button, Flex, Box } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { toaster } from "@/components/ui/toaster";
import { useUserUpdate } from "../../hooks/user";
import { onErrorShared } from "../../rpc/error";

// const INPUT_STYLE = {
//   bg: "white",
//   borderColor: "gray.400",
// };

interface FormFields {
  email: string;
  name: string;
  passwordNew: string;
}

export function NotificationSettings() {
  const {
    // register,
    handleSubmit,
    setError,
    // formState: { errors },
  } = useForm<FormFields>({
    defaultValues: {},
  });
  const updateUser = useUserUpdate({
    onError: onErrorShared({
      invalid_argument(fields) {
        const formFields = ["email", "name", "passwordNew"] as const;
        formFields.forEach((key) => {
          const message = fields[key];
          if (message) {
            setError(key, { message });
          }
        });
      },
    }),
    onSuccess() {
      toaster.create({
        title: "Profile Updated",
        type: "success",
      });
    },
  });

  function onSubmit(data: FormFields) {
    updateUser.mutate({ body: data });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box w="100%" p="5">
        <Flex justify="center">
          <Box w="80%">
            <Heading fontSize="3xl">Notifications</Heading>
            <Box pt="5">
              <Heading fontSize="lg">Profile</Heading>
              <Text>When we will contact you</Text>
            </Box>

            <Flex pt="5" justify="right">
              <Button
                mt={4}
                colorScheme="teal"
                loading={updateUser.isPending}
                type="submit"
                onClick={handleSubmit(onSubmit)}
              >
                Save
              </Button>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </form>
  );
}
